<template>
  <div>
    <v-card>
      <v-card-text class="text-center">
        <v-container fluid pa-0 v-if="!summaryLoading">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="mt-2 text-h5 black--text">
                {{ selectedZone.zcode + " " + selectedZone.name.toUpperCase() }}
              </p>
              <p class="mt-2 text-h6 black--text">
                {{
                  selectedLane.lane_text_current.toUpperCase() +
                  " - " +
                  selectedLane.lane_id
                }}
              </p>
              <v-row class="mt-8 mx-2" no-gutters id="receipt">
                <v-col>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >GATE CONFIGURED</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.gateConfigure == "1" ? "YES" : "NO"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >GATE TYPE</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData?.gateType
                        ? summaryData.gateType.toUpperCase()
                        : "-"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >GATE IP ADDRESS</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData?.gateIPAddress
                        ? summaryData.gateIPAddress
                        : "-"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >GATE MAC ADDRESS</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData?.gateMacAddress
                        ? summaryData.gateMacAddress
                        : "-"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >CC READER</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.ccConfigure == "1" ? "YES" : "NO"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >CC READER IP ADDRESS</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData?.cardReaderIPAddress
                        ? summaryData.cardReaderIPAddress
                        : "-"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >CC MAC ADDRESS</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData?.ccMacAddress
                        ? summaryData.ccMacAddress
                        : "-"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >2D SCANNER</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.scannerConfigure == "1" ? "YES" : "NO"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >PROX SCANNER</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.proxConfigure == "1" ? "YES" : "NO"
                    }}</v-col>
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >LOOPS REVERSED</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.isLoopReversed == "1" ? "YES" : "NO"
                    }}</v-col>
                  </v-row>
                  <v-divider class="mx-8 my-2"></v-divider>

                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3"
                      >CONFIGURATION STATUS</v-col
                    >
                    <v-col class="pa-0 text-right grey--text text--darken-3">{{
                      summaryData.overallPercentage == 100
                        ? "COMPLETED"
                        : "PARTIALLY COMPLETED"
                    }}</v-col>
                    <small
                      style="color: red; font-size: 12px"
                      v-show="summaryData.overallPercentage != 100"
                      >Point the QR code at Kiosk QR Scanner</small
                    >
                  </v-row>
                  <v-row class="receipt-content py-1" no-gutters>
                    <v-col
                      cols="7"
                      class="pa-0 text-left grey--text text--darken-3 align-self-center"
                      >QR CODE</v-col
                    >
                    <v-col
                      class="pa-0 text-right grey--text text--darken-3"
                      @click="openQRDialog"
                      ><vue-qrcode
                        v-if="summaryData.qrCode != ''"
                        ref="qrImage"
                        :value="summaryData.qrCode"
                        :options="{ width: 100, errorCorrectionLevel: 'M' }"
                      ></vue-qrcode
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <v-col cols="12">
              <v-btn
                block
                x-large
                class="elevation-0 primary my-2"
                @click="downloadJSONfile"
              >
                <v-icon left>download_for_offline</v-icon>download</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                block
                x-large
                tile
                class="elevation-0 my-2"
                @click="navigateToHome"
                >close</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else style="height: 80vh" fluid pa-0>
          <v-row style="height: 100%" align-content="center" justify="center">
            <v-col cols="12" class="text-subtitle-1 text-center">
              <v-progress-circular
                color="primary"
                indeterminate
                rounded
                :size="50"
              ></v-progress-circular>
              <p>Please wait...</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showQRDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span>
            {{
              selectedLane.lane_text_current.toUpperCase() +
              " - " +
              selectedLane.lane_id
            }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <v-container fluid pa-0>
            <v-row no-gutters class="mt-2">
              <v-col cols="12" class="black--text">
                <span class="mt-2 text-h5 font-weight-bold black--text">
                  {{ selectedZone.name.toUpperCase() }}
                </span>
              </v-col>
              <v-col>
                <span class="text-h6 black--text">
                  {{ selectedZone.zcode }}
                </span>
              </v-col>
              <v-col>
                <vue-qrcode
                  v-if="summaryData.qrCode != ''"
                  ref="qrImage"
                  :value="summaryData.qrCode"
                  :options="{ width: 300, errorCorrectionLevel: 'M' }"
                ></vue-qrcode>
                <p class="mt-2 black--text" style="font-size: larger">
                  Point the QR code at Kiosk QR Scanner
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text @click="showQRDialog = false" class="primary"
            >close
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>
  
  <script>
import * as API from "@/api/api";
import { mapGetters } from "vuex";
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  name: "ConfigSummary",
  components: { VueQrcode },
  data: () => ({
    showQRDialog: false,
    summaryData: {},
    summaryLoading: false,
  }),
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
      selectedLane: "pinaStore/getterSelectedLane",
    }),
  },
  watch: {},
  mounted() {
    this.fetchKioskSummary();
  },
  methods: {
    openQRDialog() {
      this.showQRDialog = true;
    },
    navigateToHome() {
      this.$router.replace({ path: "/home/" + this.selectedZone.zid + "/" });
    },
    downloadJSONfile() {
      let fileName = `${this.selectedZone.name} (${this.selectedZone.zcode}) ${this.selectedLane.lane_text_current} - ${this.selectedLane.lane_id}.json`;
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.summaryData));
      const link = document.createElement("a");
      link.setAttribute("href", dataStr);
      link.setAttribute("download", fileName);
      link.click();
    },
    async fetchKioskSummary() {
      try {
        this.summaryLoading = true;
        const reqData = {
          zid: this.$router.currentRoute.params.zid,
          laneId: this.$router.currentRoute.params.lid,
        };
        const result = await API.getKioskSummary(reqData);
        this.summaryData = result?.data?.data
          ? Object.assign({}, result?.data?.data[0])
          : Object.assign({}, {});
        this.summaryLoading = false;
      } catch (error) {
        this.summaryLoading = false;
        console.log(error);
      }
    },
  },
};
</script>
  <style>
</style>
  